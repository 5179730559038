import { makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import * as React from 'react'
import { ReactElement } from 'react'
import { ConsultationsComponent } from '../../../components/ConsultationsComponent'
import { Consultation } from '../../../components/ConsultationsComponent/ConsultationComponent'
import Layout from '../../../components/Layout'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    marginLeft: '10px'
  }
})

export const query = graphql`
  query EnConsultationsQuery {
    allContentXlsxEnConsultations {
      nodes {
        name
        place
        platform
        time1
        time2
        time3
      }
    }
  }
`

interface Nodes {
  nodes: Array<Consultation>
}

interface DataSheet {
  allContentXlsxEnConsultations: Nodes
}

export interface Props {
  data: DataSheet
}
const Consultations = ({
  data: {
    allContentXlsxEnConsultations: { nodes: consultations }
  }
}: Props): ReactElement => {
  const styles = useStyles()
  return (
    <Layout isEn actualTab="Didactics">
      <p className={styles.title}>Consultations</p>
      <ConsultationsComponent consultations={consultations} />
    </Layout>
  )
}

export default Consultations

import { graphql } from 'gatsby'
import * as React from 'react'
import Consultations, { Props } from './consultations'

export const query = graphql`
  query EnIndexConsultationsQuery {
    allContentXlsxEnConsultations {
      nodes {
        name
        place
        platform
        time1
        time2
        time3
      }
    }
  }
`

const Index = (props: Props) => <Consultations {...props} />
export default Index
